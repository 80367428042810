// Chinese
export default{
    form: {
        startSurveyButton: "开始",
        nextButton: "下一步",
        submitAnswerButton: "提交答案",
        continueButton: "继续",
        finishButton: "完成",
        headerPercentageCompletion: '已完成%',
        headerQuestionsCompleted: '问题',
        headerDefaultErrorMessage: '哎呀。出了点问题。',
        brandingPoweredBy: '技术支持',
        brandingPromoWhySurvey: '为什么做调查，而不是用<b>{product}</b>？',
        brandingPromoGetStarted: '今天就开始吧！',
        submissionPending: "正在提交您的语音表单。请不要关闭浏览器窗口。",
        submissionCanCloseWindow: "您的语音表单已成功提交。您现在可以关闭窗口了👍",
        mediaAttachmentOverlayCloseButton: "关闭",
        expiredFormHeader: "感谢您的到来！",
        expiredFormMessage: "此调查已过期。请联系语音表单创建者告知他们。",
        notPublishedFormHeader: "语音表单未发布！",
        notPublishedFormMessage: "此调查似乎尚未发布。请联系语音表单创建者告知他们。",
        notPublishedFormRetryButton: "重试",
        errorFormHeader: "哎呀！",
        errorFormMessage: "可能出了点问题。请稍后再试。",
        errorFormRetryButton: "重试",
        emptyFormHeader: "感谢您的到来！",
        emptyFormMessage: "哎呀！此表单目前为空，没有问题或字段可填写。对此给您带来的不便，我们深表歉意。请联系表单创建者，告知此问题。感谢您的理解与配合！",

        restoreFormHeader: "您有一个未完成的表单！",
        restoreFormMessage: "是否继续之前的表单填写？",
        restoreFormButton: "继续填写",
        restoreFormButtonNew: "重新开始",

        audioPermissionHeader: "是否愿意语音回答？",
        audioPermissionMessage: "语音回答将更快更方便。",
        audioPermissionAcceptButton: "是的，我愿意",
        audioPermissionDenyButton: "不，谢谢",
        audioPermissionErrorHeader: "麦克风不可用",
        audioPermissionErrorMessage: "请检查浏览器权限，启用麦克风访问。",
        audioPermissionErrorButton: "好的",
        audioRecorderFailedUpload: "录音上传失败",
        silenceDetectorError: "听不见您的声音。请检查麦克风设置。提示：可能是外部麦克风。",
        audioRecorderButtonRecord: "开始录音",
        audioRecorderButtonRecordMore: "继续录音",
        voiceResponseSelectionMessage: "选择您的回答方式...",
        voiceResponseSelectionOrDivider: "或",
        voiceResponseTextInputPlaceholder: "输入答案",
        voiceResponseTextInputCharacterCounterMinMessage: "请输入至少{min}个字符",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count}个字符（最少{min}个字符）",
        voiceResponseTextInputCharacterValidationMessage: "{count}个字符（最少{min}个字符）",

        // checkbox
        checkboxValidationTooFew: "您必须至少选择{min}个选项",
        checkboxValidationTooMany: "最多只能选择{max}个选项",
        checkboxNoneOfTheAboveOption: "以上皆无",
        checkboxInvalidAnswer: "无效答案。",

        // datepicker
        datePickerPlaceholder: "选择日期",

        // dropdown
        // email
        emailLabel: "电子邮件",
        emailInvalid: "无效的电子邮件。",
        // file-upload
        fileUploadPluginNameCamera: "相机",
        fileUploadPluginNameCameraVideo: "录制视频",
        fileUploadPluginScreenCast: "屏幕共享",
        fileUploadVideoImportFilesDefault: '通过以下方式录制或上传视频：',
        fileUploadVideoImportFilesLocalFilesDisabled: '请选择视频录制选项：',
        fileUploadVideoImportFilesNoCamera: '点击按钮屏幕录制视频',
        fileUploadVideoImportFilesNoScreenCast: '点击按钮录制视频',
        fileUploadVideoImportFilesNoCameraAndScreenCast: '没有可用的视频录制选项',

        // matrix
        matrixValidationMessage: "答案有效。",
        matrixRow: "行",
        matrixColumn: "列",
        matrixRowRequired: "行{rowTitle}为必填项。",
        matrixRadioGroup: "单选组",
        matrixCheckboxGroup: "复选组",
        matrixGroupRequired: "对于行{rowTitle}，{type}{groupTitle}是必填项。",
        matrixColumnRequired: "对于行{rowTitle}，列{columnTitle}为必填项。",
        matrixColumnInvalid: "对于行{rowTitle}，列{columnTitle}无效。",
        matrixRequired: "必填。",
        matrixNumericMustBeNumber: "必须是数字。",
        matrixNumericMustBeGreaterThenMin: "必须大于{min}。",
        matrixNumericMustBeLessThenMax: "必须小于{max}。",
        matrixNumericMustBeInteger: "必须是整数。",
        matrixNumericInvalidNumber: "无效的数字。",

        // name
        nameLabel: "姓名",
        nameInvalid: "请填写名字和姓氏。",

        // nps
        npsNotLikely: "完全不可能",
        npsExtremelyLikely: "非常可能",

        // numeric input
        numericInputRequired: "必填。",
        numericInputMustBeNumber: "必须是数字。",
        numericInputMustBeGreaterThenMin: "必须大于{min}。",
        numericInputMustBeLessThenMax: "必须小于{max}。",
        numericInputMustBeInteger: "必须是整数。",
        numericInputInvalidNumber: "无效的数字。",
        numericInputPlaceholder: "输入数字",

        // phone
        phoneInvalid: "电话号码无效。",
        phoneCountrySelectorLabel: '国家代码',
        phoneCountrySelectorError: '选择国家',
        phoneNumberLabel: '电话号码',
        phoneExample: '示例：',

        // boolean
        booleanYesLabel: "是",
        booleanNoLabel: "否",

        //questionStep
        questionStepAudioQuestionLabel: "语音问题",

        // errors
        invalidPhoneNumber: "{phone}是无效的电话号码。",
        invalidEmail: "{email}是无效的电子邮件地址。",
        questionIsRequired: "此问题为必填项。",
        answerIsNotValid: "答案无效。",
        unfinishedProbingDialogError: "请完成对话。",
        cannotResumePartialResponse: "无法继续部分回答。",
        failedToSubmitForm: "表单提交失败。请检查您的网络连接并重试。",

        //language picker
        searchLanguage: "搜索语言",
    }
}
